import React, {Component} from 'react';
import ReactPlayer from "react-player/youtube"
import './Home.css';

let _this;

class UserGuide extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this;
    }

    componentDidMount() {
        // setCurrentScreen(analytics, window.location.pathname);
        // logEvent(analytics, 'screen_view')
    }


    render() {
        return <div className="container">
            <div className="card" id="shellPrimaryNoPadding">
                <div className="top_menu containerNoMargin">
                    <ul>
                        <li>
                            <a href="/record-phone-calls-on-iphone-or-android" role="button" onClick={() => _this.props.navigate('Home')}> Home </a>
                        </li>
                        <li className="first">
                            <a href="/call-recorder-and-transcriber-user-guide" role="button"
                               onClick={() => _this.props.navigate('UserGuide')}> Tutorial </a>
                        </li>
                        <li>
                            <a href="/how-much-does-it-cost-to-record-and-transcribe-phone-call-conversation" role="button" onClick={() => _this.props.navigate('Pricing')}> Pricing </a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-terms-of-use" role="button" onClick={() => _this.props.navigate('TermsOfUse')}> Terms </a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-privacy-policy" role="button"
                               onClick={() => _this.props.navigate('PrivacyPolicy')}> Privacy </a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-frequently-asked-questions" role="button" onClick={() => _this.props.navigate('FAQ')}> FAQ </a>
                        </li>
                        <li>
                            <a href="/answer-solutions-contact" role="button"
                               onClick={() => _this.props.navigate('ContactUs')}> Support </a>
                        </li>
                    </ul>
                </div>
            </div>
            <br/>
            <p class="normal">
                <h1>Ultimate Guide to <strong>A Call Recorder App</strong> and Service</h1>
            </p>
            <br/>
            <div id="article">
                <p className="normal">
                    <h2>1. Record regular (outgoing) calls.</h2>
                </p>
                <p>
                    Open the <b>A Call Recorder App</b> and press the <b>'Recorded A Call'</b> button on the
                    right-bottom (Figure 1.1). Select the destination number from contacts, or dial it in, and when
                    you're ready, press the<b>'Start'</b> button (Figure 1.2). The App will transition to the Recording
                    screen (Figure 1.3), and in a moment, you will get a call-back from our server.
                </p>
                <p>
                    Once you answer, the service begins recording you (Figure 1.4). In the meanwhile,
                    our server will try to call the destination number. Once the person you want to talk to answers, our
                    app announces to both sides that: - <b>"The Call Is Being Recorded"</b>.
                </p>
                <p>
                    When you or the person you call drops the line, we stop recording and save the recorded audio file
                    on the cloud.
                </p>
                <p>
                    <i>
                        You can access the recorded file in the <b>'Recorded Calls'</b> screen (Figure 3.1, Figure 3.2)
                        immediately after the call ends.
                    </i>
                </p>
                <div className="row_with_vertical_padding">
                    <div className="column">
                        <img src="/ACRec.tutorial.11.webp" alt="screenshot 1" width="100%"/>
                        <center>Figure 1.1</center>
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.12.webp" alt="screenshot 2" width="100%"/>
                        <center>Figure 1.2</center>
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.13.webp" alt="screenshot 3" width="100%"/>
                        <center>Figure 1.3</center>
                    </div>
                    <div className="column">
                        <img src="/screen3.webp" alt="screenshot 3" width="100%"/>
                        <center>Figure 1.4</center>
                    </div>
                </div>
            </div>
            <div id="article">
                <p className="normal"><h2>2. Record incoming or ongoing calls.</h2></p>
                <p>
                    Answer the call, then open the <b>A Call Recorder App</b>. Press the <b>'Merge And
                    Record'</b> button on the left bottom (Figure 2.1). In a moment, you will get the call-back
                    from our server (Figure 2.2). Once answered, you will see the native multiple phone calls
                    manager screen and tap the <b>'Hold & Accept'</b> button (Figure 2.3). The service begins
                    recording you. Then, on the next screen, tap the standard <b>'merge calls'</b> button (Figure 2.4).
                </p>
                <p>
                    The mobile phone merges two lines between you and our service and the individual who contacted you.
                </p>
                <p>
                    When you drop the line, the service stops recording. Note that in some cases, the recording can stop
                    when one of the parties drops the call. Or recording can continue even after you disconnect from the
                    call. Depending on the sequences of steps you use to merge the calls and who was the originator of
                    the call!
                </p>
                <p>
                    <b>
                        <span className="crimson_text">After the call ends, always return to the app and check the app status to avoid unnecessary expenses on recorded silence!</span><br/>
                    </b>
                </p>
                <p>
                    <i>
                        You can access the recorded file in the <b>'Recorded Calls'</b> screen (Figure 3.1, Figure 3.2)
                        immediately after the call ends.
                    </i>
                </p>
                <div className="row_with_vertical_padding">
                    <div className="column">
                        <img src="/ACRec.tutorial.21.webp" alt="screenshot 2.1" width="100%"/>
                        <center>Figure 2.1</center>
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.24.webp" alt="screenshot 2.2" width="100%"/>
                        <center>Figure 2.2</center>
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.22.webp" alt="screenshot 2.3" width="100%"/>
                        <center>Figure 2.3</center>
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.23.webp" alt="screenshot 2.4" width="100%"/>
                        <center>Figure 2.4</center>
                    </div>
                </div>
            </div>
            <br/>
            <div id="article">
                <p className="normal"><h2>3. Play/Listen to the recorded calls.</h2></p>
                <p>
                    Tap the <b>'Recorded Calls'</b> option to go to the screen with all your recordings (Figure 3.1).
                    Then tap on the item you want to listen to open it. Tap the <b>'Play'</b> button. Also,
                    use the slider to go to the specific time in the recording you are most interested in (Figure 3.2).
                </p>
                <div className="row_with_vertical_padding">
                    <div className="column">
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.31.webp" alt="screenshot 3.1" width="100%"/>
                        <center>Figure 3.1</center>
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.32.webp" alt="screenshot 3.2" width="100%"/>
                        <center>Figure 3.2</center>
                    </div>
                    <div className="column">
                    </div>
                </div>
            </div>
            <br/>
            <div id="article">
                <p className="normal"><h2>4. Request transcription of the recorded call.</h2></p>
                <p>
                    Tap the <b>'Recorded Calls'</b> option to go to the screen with all your recordings (Figure 3.1).
                    Then tap on the item you want to transcribe to open it.
                </p>

                <p>
                    Tap on the <b>'Request Transcription'</b> link (Figure 4.1), choose the primary language of
                    the conversation in the opened dialog and press the <b>'Request'</b> button (Figure 4.2).
                </p>
                <p>
                    Once the transcription is ready, you will get a notification message (Figure 4.3). And the
                    label <b>'Wait for transcription...'</b> will turn into the link <b>'See Transcription'</b> (Figure
                    5.2) that will take you to the <b>'Transcription'</b> screen (Figure 4.4). On this
                    screen, you can also copy the text to the clipboard and share it through email.
                </p>
                <div className="row_with_vertical_padding">
                    <div className="column">
                        <img src="/ACRec.tutorial.41.webp" alt="screenshot 4.1" width="100%"/>
                        <center>Figure 4.1</center>
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.42.webp" alt="screenshot 4.2" width="100%"/>
                        <center>Figure 4.2</center>
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.43.webp" alt="screenshot 4.3" width="100%"/>
                        <center>Figure 4.3</center>
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.44.webp" alt="screenshot 4.4" width="100%"/>
                        <center>Figure 4.4</center>
                    </div>
                </div>

            </div>
            <br/>
            <div id="article">
                <p className="normal"><h2>5. Share a recorded audio file and a transcription of the recorded call.</h2>
                </p>
                <p>
                    Tap the <b>'Recorded Calls'</b> option to go to the screen with all your recordings (Figure 3.1).
                    Then tap on the item (recorded call) you want to share to open it. In the opened card, tap the <b>'Share
                    Audio'</b>button (Figure 5.1).
                </p>
                <p>
                    To share the text file with the transcription of the recorded call, tap the <b>'See
                    Transcription'</b> link in the middle of the opened card (Figure 5.2). We are assuming you did
                    request the transcription before and got the result. In the <b>Transcription</b> screen, tap the <b>'Share
                    Text'</b> button (Figure 5.3). You can tap the <b>'Copy Text'</b> button to copy the whole text to
                    the clipboard and <b>'Paste'</b> it in any other app.
                </p>
                <p><i>
                    Note that sharing audio and text files currently works through the standard <b>Apple Mail</b> app
                    (Figure 5.4). So, you must have the mail configured on your iPhone. Otherwise, it will not work!</i>
                </p>
                <div className="row_with_vertical_padding">
                    <div className="column">
                        <img src="/ACRec.tutorial.51.webp" alt="screenshot 5.1" width="100%"/>
                        <center>Figure 5.1</center>
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.52.webp" alt="screenshot 5.2" width="100%"/>
                        <center>Figure 5.2</center>
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.53.webp" alt="screenshot 5.3" width="100%"/>
                        <center>Figure 5.3</center>
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.54.webp" alt="screenshot 5.4" width="100%"/>
                        <center>Figure 5.4</center>
                    </div>
                </div>
            </div>
            <br/>
            <div id="article">
                <p className="normal"><h2>6. Delete the recorded call from the phone and from the cloud.
                </h2></p>
                <p>
                    Tap the <b>'Recorded Calls'</b> option to go to the screen with all your recordings (Figure 6.1).
                    Then tap on the item (recorded call) you want to delete to open it. In the opened card, tap
                    the <b>'Delete'</b> button (Figure 6.2).
                </p>
                <p><i>
                    We keep the recorded audio and transcribed text files in our cloud for at least <b>one month</b>,
                    then delete them. You can delete those files anytime, but note that the <b>Delete operation is
                    permanent</b>! We can not undo it, be careful! Also, if you want to back up important recordings or
                    transcriptions, use the <b>'Share'</b> feature and send them to yourself by email!</i></p>
                <div className="row_with_vertical_padding">
                    <div className="column">
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.31.webp" alt="screenshot 6.1" width="100%"/>
                        <center>Figure 6.1</center>
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.62.webp" alt="screenshot 6.2" width="100%"/>
                        <center>Figure 6.2</center>
                    </div>
                    <div className="column">
                    </div>
                </div>
            </div>
            <br/>
            <div id="article">
                <p className="normal"><h2>7. See the history of all transactions (Billing).
                </h2></p>
                <p>
                    Tap the <b>'Billing State & History'</b> option (Figure 7.1). It will navigate to the screen with
                    the history of all your transactions, including call recordings, transcription requests, and
                    purchases (Figure 7.2).
                </p>
                <p><i>
                    Note that to calculate the minutes for the billing, we round them to the next closest whole number
                    value. For example, if your conversation lasted 2 minutes and 55 seconds or 2 minutes and 5 seconds,
                    we will charge you for 3 minutes (this way, the Telecom provider charges us).
                </i></p>
                <div className="row_with_vertical_padding">
                    <div className="column">
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.71.webp" alt="screenshot 7.1" width="100%"/>
                        <center>Figure 7.1</center>
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.72.webp" alt="screenshot 7.2" width="100%"/>
                        <center>Figure 7.2</center>
                    </div>
                    <div className="column">
                    </div>
                </div>
            </div>
            <br/>
            <div id="article">
                <p className="normal"><h2>8. Add an Extra Credit Minutes.</h2></p>
                <p>
                    Refill your balance from the <b>Dashboard</b> screen (Figure 8.1) or the <b>Billing State &
                    History</b> screen (Figure 8.2). Tap the <b>'Add Credit'</b> button and select one of the options
                    from the purchase dialog (Figure 8.3).
                </p>
                <p><i>
                    You can’t record calls when your available credit is less than 1 minute! The gray buttons on the
                    bottom of the screen are disabled (Figure 8.4). Also, you can not request transcription for the
                    recorded call if the duration of this conversation is longer than the number of available credit
                    minutes on your balance.
                </i></p>
                <div className="row_with_vertical_padding">
                    <div className="column">
                        <img src="/ACRec.tutorial.81.webp" alt="screenshot 8.1" width="100%"/>
                        <center>Figure 8.1</center>
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.82.webp" alt="screenshot 8.2" width="100%"/>
                        <center>Figure 8.2</center>
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.83.webp" alt="screenshot 8.3" width="100%"/>
                        <center>Figure 8.3</center>
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.84.webp" alt="screenshot 8.4" width="100%"/>
                        <center>Figure 8.4</center>
                    </div>
                </div>
            </div>
            <br/>
            <div id="article">
                <p className="normal"><h2>9. Account Info, Call Settings, and Delete Account.</h2></p>
                <p>
                    To go to the Account Info screen, tap on the top right button on the dashboard screen (Figure 9.1).
                    On this screen, you can find the info about all data stored on your phone or/and cloud, including
                    your phone number, the number of recorded audio files, and the number of files with transcription.
                    If you want to remove all your data, tap on the Red delete button at the bottom of the screen, and
                    confirm your action in the opened confirmation dialog (Figure 9.2).
                </p>
                <p><i>
                    Note that you can not undo this action. When you delete your account, we delete all your files and
                    data from our cloud. Ensure you back up important audio and text files through the share option (see
                    the Article 5).
                </i></p>
                <p>
                    The Account Info screen contains additional settings. Here you can turn off the audio disclosure
                    message that the service plays at the beginning of each conversation. You can turn it off for both
                    parties (Figure 9.3).
                </p>
                <p><i><span className="crimson_text">
                    Note! You are responsible for letting all participants know the conversation is being recorded. In
                    some States, not getting consent is against the law! Please, stay compliant in two-party States!
                </span></i></p>
                <p><i>
                    For more information read the <a href="#policy" role="button"
                                                     onClick={() => _this.props.navigate('PrivacyPolicy')}>Privacy
                    Policy</a> and <a href="#terms" role="button" onClick={() => _this.props.navigate('TermsOfUse')}>Terms
                    of
                    Use</a>.
                </i></p>
                <div className="row_with_vertical_padding">
                    <div className="column">
                        <img src="/ACRec.tutorial.91.webp" alt="screenshot 9.1" width="100%"/>
                        <center>Figure 9.1</center>
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.92.webp" alt="screenshot 9.2" width="100%"/>
                        <center>Figure 9.2</center>
                    </div>
                    <div className="column">
                        <img src="/ACRec.tutorial.93.webp" alt="screenshot 9.3" width="100%"/>
                        <center>Figure 9.3</center>
                    </div>
                    <div className="column">
                    </div>
                </div>
            </div>
            <br/>
            <div id="content" className="content_card_text">
                <p className="normal"><h2>An example of transcribed phone call interview (between two bots)</h2></p>
                <div className="qr_container">
                    <ReactPlayer url="https://youtu.be/Bz7Ka9ujNjQ"/>
                </div>
            </div>
            <div className="card" id="shellPrimaryNoPadding">
                <div className="fast_nav containerNoMargin">
                    <ul>
                        <li className="first">
                            <a href="https://answersolutions.net" target="_blank" rel="noopener noreferrer">2022 © AnswerSolutions LLC</a>
                        </li>
                        <li>
                            <a href="/record-phone-calls-on-iphone-or-android" role="button" onClick={() => _this.props.navigate('Home')}>Home</a>
                        </li>
                        <li className="current">
                            <a href="/call-recorder-and-transcriber-user-guide" role="button"
                               onClick={() => _this.props.navigate('UserGuide')}>Tutorial</a>
                        </li>
                        <li>
                            <a href="/how-much-does-it-cost-to-record-and-transcribe-phone-call-conversation" role="button" onClick={() => _this.props.navigate('Pricing')}>Pricing</a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-terms-of-use" role="button" onClick={() => _this.props.navigate('TermsOfUse')}>Terms of Use</a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-privacy-policy" role="button" onClick={() => _this.props.navigate('PrivacyPolicy')}>Privacy Policy</a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-frequently-asked-questions" role="button" onClick={() => _this.props.navigate('faq')}>FAQ</a>
                        </li>
                        <li>
                            <a href="/answer-solutions-contact" role="button"
                               onClick={() => _this.props.navigate('ContactUs')}>Support</a>
                        </li>
                    </ul>
                </div>
            </div>
            <br/>
        </div>
    }
}

export default UserGuide;