import React, {Component} from 'react';
import {TagCloud} from 'react-tagcloud'
import './Home.css';

let _this;
const options = {
    luminosity: 'gray',
    hue: '#607D8B',
}
const data = [
    {value: 'Journalists', count: 38},
    {value: 'Attorneys', count: 30},
    {value: 'Sales Professionals', count: 28},
    {value: 'Assistants', count: 25},
    {value: 'Secretaries', count: 33},
    {value: 'Teachers', count: 18},
    {value: 'Politicians', count: 20},
    {value: 'Police Officers', count: 38},
    {value: 'Elderly People', count: 30},
    {value: 'Finance Professionals', count: 28},
    {value: 'Contractors', count: 25},
    {value: 'Remote Workers', count: 33},
    {value: 'Job Candidates', count: 18},
    {value: 'HR Professionals', count: 20},
    {value: 'Any Agents', count: 38},
    {value: 'Real Estates Agents', count: 30},
    {value: 'Business Owners', count: 28},
    {value: 'Students', count: 25},
    {value: 'Partners', count: 33},
    {value: 'Doctors', count: 18},
    {value: 'Patients', count: 20},
    {value: 'Freelancers', count: 33},
    {value: 'Headhunters', count: 38},
    {value: 'Pupils', count: 25},
    {value: 'Nurses', count: 18},
    {value: 'Teammates', count: 20},
    {value: 'Bloggers', count: 20},
]

class Home extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this;
    }

    render() {
        return <div className="container">
            <div className="card" id="shellPrimaryNoPadding">
                <div className="top_menu containerNoMargin">
                    <ul>
                        <li className="first">
                            <a href="/record-phone-calls-on-iphone-or-android" role="button"
                               onClick={() => _this.props.navigate('Home')}> Home </a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-user-guide" role="button"
                               onClick={() => _this.props.navigate('UserGuide')}> Tutorial</a>
                        </li>
                        <li>
                            <a href="/how-much-does-it-cost-to-record-and-transcribe-phone-call-conversation"
                               role="button" onClick={() => _this.props.navigate('Pricing')}> Pricing </a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-terms-of-use" role="button"
                               onClick={() => _this.props.navigate('TermsOfUse')}> Terms </a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-privacy-policy" role="button"
                               onClick={() => _this.props.navigate('PrivacyPolicy')}> Privacy </a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-frequently-asked-questions" role="button"
                               onClick={() => _this.props.navigate('FAQ')}> FAQ </a>
                        </li>
                        <li>
                            <a href="/answer-solutions-contact" role="button"
                               onClick={() => _this.props.navigate('ContactUs')}> Support </a>
                        </li>
                    </ul>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <h2>Who needs A Call Recorder App?</h2>
                    </p>
                    <TagCloud
                        minSize={18}
                        maxSize={35}
                        tags={data}
                        colorOptions={options}
                        disableRandomColor={false}
                        onClick={tag => alert(`'${tag.value}' was selected!`)}
                    />
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p>
                        <div>
                            <img class="roundrect" src="/logo192.png" align="left" hspace="0" vspace="0" alt="app icon"
                                 width="108px"/>
                            <p>
                                <h2>A Call Recorder App</h2>
                            </p>
                            <p><h3>Records Regular Phone Calls in the BEST Possible Quality.<br/>Transforms Recorded
                                Audio files into Readable Text Documents.</h3></p>
                        </div>
                    </p>
                    <br/>
                    <p>
                        A Call Recorder is one of the best ways to <strong>record a regular phone
                        call</strong> conversation. We use the <strong>IVR</strong> (Interactive Voice Response)
                        technology to record the phone call
                        conversation in the cloud
                        with the best possible quality.
                    </p>
                    <p>
                        Also, we use the <strong>Best-In-Class ML/AI</strong> (machine learning and artificial
                        intelligence) engine to <strong>transcribe phone calls</strong> in case you need to transform
                        recorded audio files into
                        readable text that includes speaker separation, time codes, and more.
                    </p>
                    <p>
                        <strong>The USA And Canada Phones Only!</strong><br/>
                        Currently, our app and service work for (+1) country code.<br/>
                        However, you can call any country and record phone conversations through the <strong>'Merge And
                        Call'</strong> button. For more information, please read the user guide on our website.

                    </p>
                    <p>
                        <strong>Transcription properly works for English, Spanish, and French!</strong><br/>
                        The App will record a call in any existing language.<br/>
                        However, for now, we can properly transcribe only English, Spanish, and French.
                    </p>
                    <p>
                        <b>A Call Recorder App</b> lets you:
                        <ul>
                            <li> Record phone calls in perfect quality.</li>
                            <li> Record incoming or outgoing calls or calls you are already on, even if you are in
                                headphones.
                            </li>
                            <li> Record conference call (if your plane and the conference support merging lines).
                            </li>
                            <li> Get timestamped transcription of the recorded phone call conversation if needed.</li>
                            <li> Share recorded audio files and transcribed conversations as a text document.</li>
                            <li> Simple pricing and billing without any hidden fees.</li>
                        </ul>
                    </p>
                    <p>
                        Very simple:
                        <ul>
                            <li> Once you download the app, you receive <strong>60</strong> Credit Minutes.</li>
                            <li> SignUp with your Phone Number.</li>
                            <li> Tap the <strong>'Record A Call'</strong> button and choose or dial the destination
                                number.
                            </li>
                            <li> Tap the <strong>'Merge And Record'</strong> button on the bottom left if you are
                                already on the call.
                            </li>
                            <li> Go to the history of recorded calls and playback the recorded audio files whenever you
                                want.
                            </li>
                            <li> Request Transcription to transform recorded audio files into readable text documents.
                            </li>
                            <li> Share audio and text files through email.</li>
                            <li> Purchase extra minutes when you need them.</li>
                        </ul>
                    </p>
                    <p>
                        <strong>
                            <span className="crimson_text">60 Initial Minutes for FREE!</span><br/>
                            <span className="crimson_text">No Ads!</span><br/>
                            <span className="crimson_text">No Subscription!</span>
                        </strong>
                    </p>
                    <p>
                        Mobile app is available for modern Apple and Android phones.
                    </p>
                </div>
            </div>
            <br/>
            <div id="content" className="content_card_text">
                <p className="normal"><h2>App's Preview</h2></p>
            </div>
            <div className="card" id="shellPrimaryNoPadding">
                <div id="content">
                    <div className="row">
                        <div className="column">
                            <img src="/screen1.webp" alt="screenshot 1" width="100%"/>
                        </div>
                        <div className="column">
                            <img src="/screen2.webp" alt="screenshot 2" width="100%"/>
                        </div>
                        <div className="column">
                            <img src="/screen3.webp" alt="screenshot 3" width="100%"/>
                        </div>
                        <div className="column">
                            <img src="/screen4.webp" alt="screenshot 4" width="100%"/>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <p>
                <div className="fast_nav container">
                    <h2>Download App</h2>
                    <ul>
                        <li className="first">
                            <a href="https://apps.apple.com/us/app/a-call-recorder/id1622051987"
                               target="_blank"
                               rel="noopener noreferrer">
                                <img src="/app_store.png" alt="itunes"/>
                            </a>
                        </li>
                        <li>
                            <a href="https://play.google.com/store/apps/details?id=com.answersolutions.recordacall"
                               target="_blank"
                               rel="noopener noreferrer">
                                <img src="/google_play.png" alt="google play"/>
                            </a>
                        </li>
                    </ul>
                </div>
            </p>
            <br/>
            <div className="card" id="shellPrimaryNoPadding">
                <div className="fast_nav containerNoMargin">
                    <ul>
                        <li className="first">
                            <a href="https://answersolutions.net" target="_blank" rel="noopener noreferrer">2022 ©
                                AnswerSolutions LLC</a>
                        </li>
                        <li className="current">
                            <a href="/record-phone-calls-on-iphone-or-android" role="button"
                               onClick={() => _this.props.navigate('Home')}>Home</a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-user-guide" role="button"
                               onClick={() => _this.props.navigate('UserGuide')}>Tutorial</a>
                        </li>
                        <li>
                            <a href="/how-much-does-it-cost-to-record-and-transcribe-phone-call-conversation"
                               role="button" onClick={() => _this.props.navigate('Pricing')}>Pricing</a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-terms-of-use" role="button"
                               onClick={() => _this.props.navigate('TermsOfUse')}>Terms of Use</a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-privacy-policy" role="button"
                               onClick={() => _this.props.navigate('PrivacyPolicy')}>Privacy Policy</a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-frequently-asked-questions" role="button"
                               onClick={() => _this.props.navigate('FAQ')}>FAQ</a>
                        </li>
                        <li>
                            <a href="/answer-solutions-contact" role="button"
                               onClick={() => _this.props.navigate('ContactUs')}>Support</a>
                        </li>
                    </ul>
                </div>
            </div>
            <br/>
        </div>
    }
}

export default Home;