import React, {Component} from 'react';
import './Home.css';

let _this;

class Pricing extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this;

    }

    componentDidMount() {

    }

    render() {
        return <div className="container">
            <div className="card" id="shellPrimaryNoPadding">
                <div className="top_menu containerNoMargin">
                    <ul>
                        <li>
                            <a href="/record-phone-calls-on-iphone-or-android" role="button"
                               onClick={() => _this.props.navigate('Home')}> Home </a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-user-guide" role="button"
                               onClick={() => _this.props.navigate('UserGuide')}> Tutorial </a>
                        </li>
                        <li className="first">
                            <a href="/how-much-does-it-cost-to-record-and-transcribe-phone-call-conversation"
                               role="button" onClick={() => _this.props.navigate('Pricing')}> Pricing </a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-terms-of-use" role="button"
                               onClick={() => _this.props.navigate('TermsOfUse')}> Terms </a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-privacy-policy" role="button"
                               onClick={() => _this.props.navigate('PrivacyPolicy')}> Privacy </a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-frequently-asked-questions" role="button"
                               onClick={() => _this.props.navigate('FAQ')}> FAQ </a>
                        </li>
                        <li>
                            <a href="/answer-solutions-contact" role="button"
                               onClick={() => _this.props.navigate('ContactUs')}> Support </a>
                        </li>
                    </ul>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content" className="announce_card_text">
                    <p className="normal"><h1>Simple pricing and billing for everyone</h1></p>
                    <p><h3>
                        With Pre-Payed-Credit Minutes, you always have complete control and transparency over how much
                        you pay for our services.
                    </h3></p>
                    <p><h3>
                        Use the purchased credit minutes to record phone call conversations or transcribe those
                        recording and convert them into a time-coded text document.
                    </h3></p>
                    <p><h2>There are NO HIDDEN fees!</h2></p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content" className="content_card_text">
                    <p className="normal"><h2>Default Credit Minutes</h2></p>
                    <p><h3>
                        Once you download the app, you get <strong>60 Credit Minutes</strong>, enough to record 15
                        minutes of a call phone conversation. Then you can use the rest of the credit to transcribe this
                        recording (Transcribing 15 minutes of audio costs 15 * 3 = 45 credit minutes). Of course, you
                        can spend the entire 60 minutes recording the phone calls.
                    </h3></p>
                </div>
                <div className="row_centered">
                    <div className="columnEmpty">
                    </div>
                    <div className="columnSingle">
                        <h3>Initial Bundle</h3>
                        <div className="card" id="shellPrimaryNoPadding">
                            <div id="content" className="price_card_text">
                                <h3>Credit Minutes</h3>
                                <h3><strong>60 / 20</strong></h3>
                                <h4>Record / Transcribe</h4>
                            </div>
                        </div>
                    </div>
                    <div className="columnEmpty">
                    </div>
                </div>
                <br/>
                <div id="content" className="content_card_text">
                    <p><h3>
                        To transcribe 1-minute audio, you need to spend <strong>3 Credit Minutes</strong>!
                    </h3></p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content" className="content_card_text">
                    <p className="normal"><h2>Extra Credit Minutes</h2></p>
                    <p><h3>
                        Once your account balance has dropped below the number, you need to record the next phone call,
                        or you need to transcribe a previously recorded conversation. You need to purchase extra credit
                        minutes.
                    </h3></p>
                </div>
                <div className="row_centered">
                    <div className="column">
                        <h3>Small Bundle</h3>
                        <h3><strong>$4.99 USD</strong></h3>
                        <div className="card" id="shellPrimaryNoPadding">
                            <div id="content" className="price_card_text">
                                <div id="content" className="price_card_text">
                                    <h3>Credit Minutes</h3>
                                    <h3>&nbsp;&nbsp;60 / 20&nbsp;&nbsp;</h3>
                                    <h4>Record / Transcribe</h4>
                                    <h4>$0.08 / $0.25 per min.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <h3>Medium Bundle</h3>
                        <h3><strong>$21.99 USD</strong></h3>
                        <div className="card" id="shellPrimaryNoPadding">
                            <div id="content" className="price_card_text">
                                <div id="content" className="price_card_text">
                                    <h3>Credit Minutes</h3>
                                    <h3>300 / 100</h3>
                                    <h4>Record / Transcribe</h4>
                                    <h4>$0.07 / $0.21 per min.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <h3>Large Bundle</h3>
                        <h3><strong>$35.99 USD</strong></h3>
                        <div className="card" id="shellPrimaryNoPadding">
                            <div id="content" className="price_card_text">
                                <div id="content" className="price_card_text">
                                    <h3>Credit Minutes</h3>
                                    <h3>600 / 200</h3>
                                    <h4>Record / Transcribe</h4>
                                    <h4>$0.06 / $0.18 per min.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <div id="content" className="content_card_text">
                    <p><h3>
                        <strong>One (1)</strong> 'Credit Minute' equals one minute of recorded call conversation. You
                        must spend <strong> three (3)</strong> ’Credit Minutes’ to <strong>transcribe</strong> a
                        one-minute-long recorded audio file.
                    </h3></p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content" className="announce_card_text">
                    <h2><p>Note that the credit minutes that you purchased shall never expire!</p></h2>
                </div>
            </div>
            <br/>
            <br/>
            <div className="card" id="shellPrimaryNoPadding">
                <div className="fast_nav containerNoMargin">
                    <ul>
                        <li className="first">
                            <a href="https://answersolutions.net" target="_blank" rel="noopener noreferrer">2022 ©
                                AnswerSolutions LLC</a>
                        </li>
                        <li>
                            <a href="/record-phone-calls-on-iphone-or-android" role="button"
                               onClick={() => _this.props.navigate('Home')}>Home</a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-user-guide" role="button"
                               onClick={() => _this.props.navigate('UserGuide')}>Tutorial</a>
                        </li>
                        <li className="current">
                            <a href="/how-much-does-it-cost-to-record-and-transcribe-phone-call-conversation"
                               role="button" onClick={() => _this.props.navigate('Pricing')}>Pricing</a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-terms-of-use" role="button"
                               onClick={() => _this.props.navigate('TermsOfUse')}>Terms Of Use</a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-privacy-policy" role="button"
                               onClick={() => _this.props.navigate('PrivacyPolicy')}>Privacy Policy</a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-frequently-asked-questions" role="button"
                               onClick={() => _this.props.navigate('faq')}>FAQ</a>
                        </li>
                        <li>
                            <a href="/answer-solutions-contact" role="button"
                               onClick={() => _this.props.navigate('ContactUs')}>Support</a>
                        </li>
                    </ul>
                </div>
            </div>
            <br/>
        </div>
    }
}

export default Pricing;