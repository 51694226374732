import React, {Component} from 'react';
import './Home.css';

let _this;

class FAQ extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this;

    }

    componentDidMount() {
        // setCurrentScreen(analytics, window.location.pathname);
        // logEvent(analytics, 'screen_view')
    }


    render() {
        return <div className="container">
            <div className="card" id="shellPrimaryNoPadding">
                <div className="top_menu containerNoMargin">
                    <ul>
                        <li>
                            <a href="/record-phone-calls-on-iphone-or-android" role="button" onClick={() => _this.props.navigate('Home')}> Home </a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-user-guide" role="button"
                               onClick={() => _this.props.navigate('UserGuide')}> Tutorial </a>
                        </li>
                        <li>
                            <a href="/how-much-does-it-cost-to-record-and-transcribe-phone-call-conversation" role="button" onClick={() => _this.props.navigate('Pricing')}> Pricing </a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-terms-of-use" role="button" onClick={() => _this.props.navigate('TermsOfUse')}> Terms </a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-privacy-policy" role="button"
                               onClick={() => _this.props.navigate('PrivacyPolicy')}> Privacy </a>
                        </li>
                        <li className="first">
                            <a href="/call-recorder-and-transcriber-frequently-asked-questions" role="button" onClick={() => _this.props.navigate('FAQ')}> FAQ </a>
                        </li>
                        <li>
                            <a href="/answer-solutions-contact" role="button"
                               onClick={() => _this.props.navigate('ContactUs')}> Support </a>
                        </li>
                    </ul>
                </div>
            </div>
            <br/>
            <p className="normal">
                <h1>Frequently asked questions</h1>
            </p>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p className="normal">
                        <h2>1. Can I record a conference call or a meeting?</h2>
                    </p>
                    <p>
                        Yes, if the meeting has the dial-in option! Dial in the meeting, press the ‘Merge And Record’
                        button on the A Call Recorder home screen, answer the callback call, and use the phone's 'Merge'
                        button to merge those lines. For more details, go to the <a href="#guide"
                                                                                    role="button"
                                                                                    onClick={() => _this.props.navigate('UserGuide')}>Tutorial</a>.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p className="normal">
                        <h2>2. Can I record a call conversation with the caller or callee outside Canada or US?</h2>
                    </p>
                    <p>
                        Unfortunately, you can’t choose the ‘Call and Record’ option to call abroad right now, but we’re
                        working on an update that will let you call to and from other countries.
                    </p>
                    <p>
                        Luckily, you can always use the <b>‘Merge And Record’</b> option!
                        Either way, call your foreign destination by pressing the ‘Merge And Record’ button in the app,
                        answer the callback call from A Call Recorder, and merge it with the ongoing call. Or first,
                        press the ‘Merge And Record’ button, answer the callback call and then add your foreign
                        destination to the call using your standard phone plan. For more information, go to the tutorial
                        and read article 2 (Record incoming calls or merge with the ongoing call).
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p className="normal">
                        <h2>3. What languages A Call Recorder supports, and conversations on what languages can be
                            transcribed?</h2>
                    </p>
                    <p>
                        We will record a call in any existing language, but now we can transcribe only English, Spanish,
                        and French. But we’re working on an update that will transcribe other languages.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p className="normal">
                        <h2>4. Can I record a call for free?</h2>
                    </p>
                    <p>
                        Unfortunately you can’t.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p className="normal">
                        <h2>5. Do you offer volume discounts?</h2>
                    </p>
                    <p>
                        Please reach out to support@answersolutions.net to see if can provide a volume discount.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p className="normal">
                        <h2>6. How fast does it take to get the recorded call audio and transcription?</h2>
                    </p>
                    <p>
                        The audio file of the recorded call is available immediately after the call ends. The
                        transcription takes around 50%-75% of the call duration to process. For example, processing a
                        20-minute call might take 10-15 minutes. Note that you need to request transcription through the
                        app manually.
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p className="normal">
                        <h2>7. How does billing work?</h2>
                    </p>
                    <p>
                        Once you download the app, you receive <strong>60 minutes in credit</strong>. Your account's
                        funds will be drained as you record the calls in the app or you request the transcription of
                        recorded calls. <strong>60 Credit Minutes</strong> is enough to record 15 minutes of a call
                        phone conversation, and then you can use the rest of the credit to transcribe this recording
                        (Transcribing 15 minutes of audio costs 15 * 3 = 45 credit minutes). Of course, you can spend
                        the entire 60 minutes recording the phone calls.
                    </p>
                    <p>
                        You can purchase extra credit minutes - once your account balance has dropped below the number
                        of credit minutes, you need to record the next call or transcribe a previously recorded
                        conversation.
                    </p>
                    <p><span className="crimson_text">For more information got to <a href="#price"
                                                                                     role="button"
                                                                                     onClick={() => _this.props.navigate('Pricing')}>Pricing</a> page!</span>
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <p className="normal">
                        <h2>8. How can I talk to someone if I want to give a feedback or suggestion?</h2>
                    </p>
                    <p>
                        Feel free to email us at feedback@answersolutions.net
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellPrimaryNoPadding">
                <div className="fast_nav containerNoMargin">
                    <ul>
                        <li className="first">
                            <a href="https://answersolutions.net" target="_blank" rel="noopener noreferrer">2022 © AnswerSolutions LLC</a>
                        </li>
                        <li>
                            <a href="/record-phone-calls-on-iphone-or-android" role="button" onClick={() => _this.props.navigate('Home')}>Home</a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-user-guide" role="button"
                               onClick={() => _this.props.navigate('UserGuide')}>Tutorial</a>
                        </li>
                        <li>
                            <a href="/how-much-does-it-cost-to-record-and-transcribe-phone-call-conversation" role="button" onClick={() => _this.props.navigate('Pricing')}>Pricing</a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-terms-of-use" role="button" onClick={() => _this.props.navigate('TermsOfUse')}>Terms Of Use</a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-privacy-policy" role="button" onClick={() => _this.props.navigate('PrivacyPolicy')}>Privacy Policy</a>
                        </li>
                        <li className="current">
                            <a href="/call-recorder-and-transcriber-frequently-asked-questions" role="button" onClick={() => _this.props.navigate('faq')}>FAQ</a>
                        </li>
                        <li>
                            <a href="/answer-solutions-contact" role="button"
                               onClick={() => _this.props.navigate('ContactUs')}>Support</a>
                        </li>
                    </ul>
                </div>
            </div>
            <br/>
        </div>
    }
}

export default FAQ;