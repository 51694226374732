import React, {Component} from 'react';
import './Home.css';
let _this;

class HowToRecordCall extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this;

    }

    componentDidMount() {
        // setCurrentScreen(analytics, window.location.pathname);
        // logEvent(analytics, 'screen_view')
    }


    render() {
        return <div className="container">
            <div className="card" id="shellPrimaryNoPadding">
                <div className="top_menu containerNoMargin">
                    <ul>
                        <li>
                            <a href="#home" role="button" onClick={() => _this.props.navigate('Home')}>Home</a>
                        </li>
                        <li>
                            <a href="#guide" role="button" onClick={() => _this.props.navigate('UserGuide')}>Tutorial</a>
                        </li>
                        <li>
                            <a href="#price" role="button" onClick={() => _this.props.navigate('Pricing')}>Pricing</a>
                        </li>
                        <li>
                            <a href="#terms" role="button" onClick={() => _this.props.navigate('TermsOfUse')}>Terms Of
                                Use</a>
                        </li>
                        <li>
                            <a href="#privacy" role="button" onClick={() => _this.props.navigate('PrivacyPolicy')}>Privacy</a>
                        </li>
                        <li>
                            <a href="#faq" role="button" onClick={() => _this.props.navigate('FAQ')}>FAQ</a>
                        </li>
                        <li className="first">
                            <a href="#contact" role="button" onClick={() => _this.props.navigate('ContactUs')}>Support</a>
                        </li>
                    </ul>
                </div>
            </div>
            <br/>
            <p className="normal">
                <h1>Product Support And Feedback
                </h1>
            </p>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <h2 className="policy">How to Record a Phone Call conversations on Your iPhone or Android</h2>
                    <p>
                        When submitting a complaint, please provide a brief description of the nature of your complaint
                        and the specific services to which your complaint relates.
                    </p>
                    <p>
                        Send an Email to: <a href="mailto:support@answersolutions.net?subject=A Call Recorder App">Support</a>
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <h2 className="policy">Do you want to give a feedback or suggestion?</h2>
                    <p>
                        Send an Email to: <a href="mailto:feedback@answersolutions.net?subject=A Call Recorder App">Feedback</a>
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <h2 className="policy">Do you have a business offer?</h2>
                    <p>
                        Send an Email to: <a href="mailto:sales@answersolutions.net?subject=A Call Recorder App">Sales</a>
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellPrimaryNoPadding">
                <div className="fast_nav containerNoMargin">
                    <ul>
                        <li className="first">
                            <a href="https://answersolutions.net" target="_blank" rel="noopener noreferrer">2022 © AnswerSolutions LLC</a>
                        </li>
                        <li>
                            <a href="#home" role="button" onClick={() => _this.props.navigate('Home')}>Home</a>
                        </li>
                        <li>
                            <a href="#guide" role="button" onClick={() => _this.props.navigate('UserGuide')}>Tutorial</a>
                        </li>
                        <li>
                            <a href="#price" role="button" onClick={() => _this.props.navigate('Pricing')}>Pricing</a>
                        </li>
                        <li>
                            <a href="#terms" role="button" onClick={() => _this.props.navigate('TermsOfUse')}>Terms Of Use</a>
                        </li>
                        <li>
                            <a href="#privacy" role="button" onClick={() => _this.props.navigate('PrivacyPolicy')}>Privacy Policy</a>
                        </li>
                        <li>
                            <a href="#faq" role="button" onClick={() => _this.props.navigate('faq')}>FAQ</a>
                        </li>
                        <li className="current">
                            <a href="#contact" role="button" onClick={() => _this.props.navigate('ContactUs')}>Support</a>
                        </li>
                    </ul>
                </div>
            </div>
            <br/>
        </div>
    }
}

export default HowToRecordCall;