import React, {Component} from 'react';

import Home from "./Home";
import PrivacyPolicy from "./PrivacyPolicy"
import TermsOfUse from "./TermsOfUse"
import UserGuide from"./UserGuide"
import Pricing from "./Pricing"
import FAQ from"./FAQ"
import ContactUs from "./ContactUs"
// import Locker from "./Locker"
import './App.css';
import HowToRecordCall from "./HowToRecordCall";

let _this;

class ACallRecorderApp extends Component {
  constructor(props, context) {
    super(props, context);

    // let init_screen = 'Locker';
    let init_screen = 'Home';
    let code = '';
    // if (window.location.hash.endsWith(".Cannotstop")) {
    //   code = 'Cannotstop'
    // }
    console.log('window.location.pathname=>' + window.location);
    //block begins, to support mobile apps, todo: remove after update
    if (window.location.hash.startsWith('#home')){
      init_screen = 'Home';
    } else if (window.location.hash.startsWith('#contact')){
      init_screen = 'ContactUs';
    } else  if (window.location.hash.startsWith('#policy')){
      init_screen = 'PrivacyPolicy';
    } else  if (window.location.hash.startsWith('#terms')){
      init_screen = 'TermsOfUse';
    } else  if (window.location.hash.startsWith('#guide')){
      init_screen = 'UserGuide';
    } else  if (window.location.hash.startsWith('#faq')){
      init_screen = 'FAQ';
    } else  if (window.location.hash.startsWith('#price')){
      init_screen = 'Pricing';
      //block ends, to support mobile apps, todo: remove after update
    } else
      if (window.location.pathname.endsWith('record-phone-calls-on-iphone-or-android')){
      init_screen = 'Home';
    } else if (window.location.pathname.endsWith('answer-solutions-contact')){
      init_screen = 'ContactUs';
    } else  if (window.location.pathname.endsWith('call-recorder-and-transcriber-privacy-policy')){
      init_screen = 'PrivacyPolicy';
    } else  if (window.location.pathname.endsWith('call-recorder-and-transcriber-terms-of-use')){
      init_screen = 'TermsOfUse';
    } else  if (window.location.pathname.endsWith('call-recorder-and-transcriber-user-guide')){
      init_screen = 'UserGuide';
    } else  if (window.location.pathname.endsWith('call-recorder-and-transcriber-frequently-asked-questions')){
      init_screen = 'FAQ';
    } else  if (window.location.pathname.endsWith('how-much-does-it-cost-to-record-and-transcribe-phone-call-conversation')){
      init_screen = 'Pricing';
    } else if(window.location.pathname.endsWith('how-to-record-phone-calls-on-your-iphone-or-android')) {
      init_screen = 'HowToRecord';
    }
      
    this.state = {
      target: init_screen,
      //todo: remove
      // pin_code: ''
      pin_code: code
    };
    _this = this;

  }

  componentDidMount() {
    console.log('componentDidMount=>');
  }

  navigate(new_target) {
    console.log('navigate=>' + new_target);
    _this.setState({
      target: new_target
    });
  };

  navigateWithPin(pin, new_target) {
    console.log('pin=>' + pin);
    _this.setState({
      target: new_target,
      pin_code: pin
    });
  };

  render() {
    let screen = null;
    // if (this.state.pin_code !== 'Cannotstop') {
    //   screen = <Locker navigateWithPin={this.navigateWithPin}/>
    // } else
    if (this.state.target === 'Home') {
      screen = <Home navigate={this.navigate}/>
    } else if (this.state.target === 'ContactUs') {
      screen = <ContactUs navigate={this.navigate}/>
    } else if (this.state.target === 'PrivacyPolicy') {
      screen = <PrivacyPolicy navigate={this.navigate}/>
    } else if (this.state.target === 'TermsOfUse') {
      screen = <TermsOfUse navigate={this.navigate}/>
    } else if (this.state.target === 'UserGuide') {
      screen = <UserGuide navigate={this.navigate}/>
    } else if (this.state.target === 'FAQ') {
      screen = <FAQ navigate={this.navigate}/>
    } else if (this.state.target === 'Pricing') {
      screen = <Pricing navigate={this.navigate}/>
    }  else if (this.state.target === 'HowToRecord') {
      screen = <HowToRecordCall navigate={this.navigate}/>
    } else {
      screen = <Home navigate={this.navigate}/>
      // screen = <Locker navigate={this.navigate}/>
    }

    return (
        <div className="App">
          {screen}
        </div>
    );
  }
}

export default ACallRecorderApp;
