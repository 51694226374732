import React, {Component} from 'react';
import './Home.css';
let _this;

class ContactUs extends Component {
    constructor(props, context) {
        super(props, context);
        _this = this;

    }

    componentDidMount() {
        // setCurrentScreen(analytics, window.location.pathname);
        // logEvent(analytics, 'screen_view')
    }


    render() {
        return <div className="container">
            <div className="card" id="shellPrimaryNoPadding">
                <div className="top_menu containerNoMargin">
                    <ul>
                        <li>
                            <a href="/record-phone-calls-on-iphone-or-android" role="button" onClick={() => _this.props.navigate('Home')}> Home </a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-user-guide" role="button" onClick={() => _this.props.navigate('UserGuide')}> Tutorial </a>
                        </li>
                        <li>
                            <a href="/how-much-does-it-cost-to-record-and-transcribe-phone-call-conversation" role="button" onClick={() => _this.props.navigate('Pricing')}> Pricing </a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-terms-of-use" role="button" onClick={() => _this.props.navigate('TermsOfUse')}> Terms </a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-privacy-policy" role="button" onClick={() => _this.props.navigate('PrivacyPolicy')}> Privacy </a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-frequently-asked-questions" role="button" onClick={() => _this.props.navigate('FAQ')}> FAQ </a>
                        </li>
                        <li className="first">
                            <a href="/answer-solutions-contact" role="button" onClick={() => _this.props.navigate('ContactUs')}> Support </a>
                        </li>
                    </ul>
                </div>
            </div>
            <br/>
            <p className="normal">
                <h1>Product Support And Feedback
                </h1>
            </p>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <h2 className="policy">Have an issue with the app or service?</h2>
                    <p>
                        When submitting a complaint, please provide a brief description of the nature of your complaint
                        and the specific services to which your complaint relates.
                    </p>
                    <p>
                        Send an Email to: <a href="mailto:support@answersolutions.net?subject=A Call Recorder App">Support</a>
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <h2 className="policy">Do you want to give a feedback or suggestion?</h2>
                    <p>
                        Send an Email to: <a href="mailto:feedback@answersolutions.net?subject=A Call Recorder App">Feedback</a>
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellWhite">
                <div id="content">
                    <h2 className="policy">Do you have a business offer?</h2>
                    <p>
                        Send an Email to: <a href="mailto:sales@answersolutions.net?subject=A Call Recorder App">Sales</a>
                    </p>
                </div>
            </div>
            <br/>
            <div className="card" id="shellPrimaryNoPadding">
                <div className="fast_nav containerNoMargin">
                    <ul>
                        <li className="first">
                            <a href="https://answersolutions.net" target="_blank" rel="noopener noreferrer">2022 © AnswerSolutions LLC</a>
                        </li>
                        <li>
                            <a href="/record-phone-calls-on-iphone-or-android" role="button" onClick={() => _this.props.navigate('Home')}>Home</a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-user-guide" role="button" onClick={() => _this.props.navigate('UserGuide')}>Tutorial</a>
                        </li>
                        <li>
                            <a href="/how-much-does-it-cost-to-record-and-transcribe-phone-call-conversation" role="button" onClick={() => _this.props.navigate('Pricing')}>Pricing</a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-terms-of-use" role="button" onClick={() => _this.props.navigate('TermsOfUse')}>Terms Of Use</a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-privacy-policy" role="button" onClick={() => _this.props.navigate('PrivacyPolicy')}>Privacy Policy</a>
                        </li>
                        <li>
                            <a href="/call-recorder-and-transcriber-frequently-asked-questions" role="button" onClick={() => _this.props.navigate('faq')}>FAQ</a>
                        </li>
                        <li className="current">
                            <a href="/answer-solutions-contact" role="button" onClick={() => _this.props.navigate('ContactUs')}>Support</a>
                        </li>
                    </ul>
                </div>
            </div>
            <br/>
        </div>
    }
}

export default ContactUs;